/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Bruges"), React.createElement("p", null, "Pour se loger à Bruges, le ", React.createElement("a", {
    href: "https://www.booking.com/district/be/brugge/historicalcentreofbrugge.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "centre historique"), " est le point de repère essentiel. On y trouve en effet la majorité des attractions touristiques.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
